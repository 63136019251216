import React from 'react'
import styles from './grid-list-switch.module.css'
import {gsap} from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

const ToggleView = ({isListView, setIsListView, resetFilters}) => {
    const handleToggleClick = (isList) => {
        resetFilters();
        setIsListView(isList);

        gsap.to(window, { duration: 1, scrollTo: { y: '#companies-section', offsetY: 333 }, ease: 'power4.inOut' })
    };

    return (
        <div className={styles.switchersWrap}>
            <div onClick={() => handleToggleClick(false)}
                 className={`${styles.toggleButton} ${!isListView ? styles.active : ''}`}>
                Featured
            </div>
            <div onClick={() => handleToggleClick(true)}
                 className={`${styles.toggleButton} ${isListView ? styles.active : ''}`}>
                List View
            </div>
        </div>
    );
};

export default ToggleView;