import React, { useImperativeHandle, useRef, useState } from 'react'
import Icon from './icons'
import styles from './filter.module.css'

const Filter = React.forwardRef(({ title, options, filter, filterHandler, isOpen, handleOpenFilter }, ref) => {
  const [selectedOption, setSelectedOption] = useState(title)
  const targetRef = useRef(null)

  const processFilter = (value) => {
    setSelectedOption(value === 'all' ? title : value)
    filterHandler([filter, value])
    handleOpenFilter(null)
  }

  const toggleFilter = () => {
    handleOpenFilter(isOpen ? null : filter)
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      setSelectedOption(title)
    }
  }))

  return (
    <div className={`${styles.filter} ${filter} ${isOpen ? styles.open : ''}`} onClick={toggleFilter} ref={targetRef}>
      <span className={styles.label}>
        {selectedOption} <Icon symbol='chevrondown' />
      </span>
      {isOpen && (
        <div className={styles.list}>
          <span className={`${selectedOption === title ? styles.current : ''}`} onClick={() => processFilter('all')}>
            All {title}
          </span>
          {options && options.map(option => (
            <span className={selectedOption === option.title ? styles.current : ''} key={option.id} onClick={() => processFilter(option.title)}>
              {option.title}
            </span>
          ))}
        </div>
      )}
    </div>
  )
})

export default Filter
